
@tailwind base;
@tailwind components;
@tailwind utilities;
.app {
  background-image:url("./images/banner.png");
  background-size: cover;
  height: 100vh;
  background-position: 50% 85%;
  font-weight: 600 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.title {
  font-family: 'Permanent Marker', cursive;
}
.breaker1{
  background-image: linear-gradient(177deg,rgb(24,24,27) 50%,#FFFFFF 51%);
}
.breaker2{
  background-image: linear-gradient(177deg,#FFFFFF 50%,rgb(24,24,27)  51%);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
